
export default {
  name: 'HeaderButtonMediclub',

  computed: {
    hasComponentLink () {
      return this.$cart.isCartReadOnly()
        ? 'span'
        : 'NuxtLink'
    },
  },

}
