import baseLink from './baseLink'

export default {
  mixins: [baseLink],

  props: {
    identifier: {
      type: String,
      required: true,
    },
    mobileIdentifier: {
      type: String,
      default: undefined,
    },
    isLoggedMediclub: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    block () {
      return this.$store.state.staticBlock.blocks[this.identifier]
    },

    mobileBlock () {
      return this.$store.state.staticBlock.blocks[this.mobileIdentifier]
    },

    isJson () {
      return this.block?.isJson ?? false
    },

    isLogged () {
      return this.$store.state.user.isLogged
    },

    blockContent () {
      let content

      try {
        content = JSON.parse(JSON.stringify(this.block.contentJson))

        // if (this.identifier === 'mediclub-block-v3-aktywnosc-fizyczna-2') {
        //   content.items[1].to = '/rul'
        // }
      } catch {
        if (this.fallbackContent && 'dev' in this.$route.query) {
          // eslint-disable-next-line no-console
          console.log(
            `%c${this.identifier}`,
            'background: #d50764; color: white',
            JSON.stringify(this.fallbackContent),
          )
        }

        content = this.fallbackContent ?? ''
      }

      return content
    },

    mobileBlockContent () {
      let content

      try {
        content = JSON.parse(JSON.stringify(this.mobileBlock.contentJson))
      } catch {
        content = this.fallbackMobileContent ?? ''
      }

      return content
    },
  },
}
