export default function (to, from, savedPosition) {
  if (savedPosition) {
    return savedPosition
  }

  if (
    from.fullPath === to.fullPath ||
    (from.path.includes('/c/') && to.path.includes('/c/') && from.query.page === to.query.page)) {
    return {}
  }

  return { x: 0, y: 0 }
}
