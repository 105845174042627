const isValidCnp = (cnp) => {
  if (typeof cnp !== 'string') { return false }
  if (cnp.length !== 13) { return false }

  const weight = [2, 7, 9, 1, 4, 6, 3, 5, 8, 2, 7, 9]
  let sum = 0

  for (let i = 0; i < weight.length; i++) {
    if (i < 12) { sum += (cnp[i] * weight[i]) }
  }

  sum = sum % 11
  if (sum === 10) { sum = 1 }

  return parseInt(cnp[12]) === sum
}

const cnp = {
  validate (value) {
    return isValidCnp(value)
  },
}

export { cnp }
