import Vue from 'vue'
import BaseLoader from '~/components/base/BaseLoader'

const BaseLoaderComponent = Vue.extend(BaseLoader)
const LoaderInstance = new BaseLoaderComponent({}).$mount()

Vue.directive('loader', {
  bind (el, { value }) {
    if (value) {
      el.dataset.loader = true
      el.appendChild(LoaderInstance.$el)
    }
  },
  update (el, { value }) {
    if (value && !el.dataset.loader) {
      el.dataset.loader = true
      el.appendChild(LoaderInstance.$el)
    } else if (!value && el.dataset.loader) {
      delete el.dataset.loader
      el.removeChild(LoaderInstance.$el)
    }
  },
})
