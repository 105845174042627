import Vue from 'vue'
import BaseLoader from '~/components/base/BaseLoader'
import BaseProgressBar from '~/components/base/BaseProgressBar'

export default ({ app }, inject) => {
  const generateVueInstance = (bemModifiers, text, type) => {
    let VueComponent = null

    if (type === 'progress-bar') {
      VueComponent = Vue.extend(BaseProgressBar)
    } else {
      VueComponent = Vue.extend(BaseLoader)
    }

    return new VueComponent({
      propsData: {
        bemModifiers,
        text,
        type,
      },
    }).$mount()
  }

  const add = ({ targetNode, bemModifiers, text, type }) => {
    const newLoader = generateVueInstance(bemModifiers, text, type)

    targetNode.appendChild(newLoader.$el)

    return newLoader
  }

  const remove = ({ instance: loaderInstance }) => {
    loaderInstance.$el.parentNode.removeChild(loaderInstance.$el)
    loaderInstance.$destroy()
  }

  inject('loader', {
    add,
    remove,
  })
}
