
export default (ctx, inject) => {
  inject('showOverlay', () => {
    document.querySelector('body').classList.add('page-overlay')
  })

  inject('hideOverlay', () => {
    document.querySelector('body').classList.remove('page-overlay')
  })
}
