
export default {
  name: 'FooterSocials',

  props: {
    json: {
      type: Object,
      required: true,
    },
  },
}
