const cache = new Map()

export function cacheGet (key) {
  const data = cache.get(JSON.stringify(key))

  return data ? JSON.parse(JSON.stringify(data)) : null
}

export function cacheSet (key, value) {
  cache.set(JSON.stringify(key), JSON.parse(JSON.stringify(value)))
}
