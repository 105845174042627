
export default {
  name: 'RatingPresentation',

  computed: {
    storeReview () {
      return this.$store.state.storeConfig.storeReview
    },

    averageRating () {
      return Intl.NumberFormat(this.$i18n.locale, {
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
      }).format(this.storeReview?.averageRating ?? 0)
    },

    count () {
      return this.storeReview?.count ?? 0
    },
  },

  methods: {
    isFull (index) {
      return this.storeReview?.averageRating >= index
    },

    isHalf (index) {
      return this.storeReview?.averageRating > index - 0.9 && this.storeReview?.averageRating < index
    },
  },
}
