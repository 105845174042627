
export default {
  name: 'BaseHeading',
  props: {
    tag: {
      type: String,
      default: null,
    },

    level: {
      type: Number,
      default: 1,
    },
  },

  computed: {
    componentTag () {
      return this.tag || `h${this.level}`
    },
  },
}
