
import { createNamespacedHelpers } from 'vuex'
import payments from '@vendor/config/footer-payments'
import storeCode from '~/mixins/storeCode'
import countryCode from '~/mixins/countryCode'
import configTypes from '~/store/storeConfig/types'

const {
  mapGetters: mapGettersConfig,
} = createNamespacedHelpers('storeConfig')

const {
  mapState: mapStateConfig,
} = createNamespacedHelpers('storeConfig')

export default {
  name: 'TheFooterV2',

  mixins: [
    storeCode,
    countryCode,
  ],

  data: () => ({
    payments,
    linksRo: [
      { name: 'anpc-sal', width: 120, url: 'https://www.anpc.gov.ro/' },
      { name: 'anpc-sol', width: 100, url: 'https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=RO' },
    ],
  }),

  computed: {
    ...mapStateConfig(['storeConfig', 'footer']),

    ...mapGettersConfig({
      isMegaMenuAvailable: configTypes.IS_MEGAMENU_AVAILABLE,
    }),

    isReservationTimerAvailable () {
      return this.$cart.getTimeLeft()
    },

    contactSection () {
      return this.footer.find(({ _typename }) => _typename === 'ContactsSection')
    },

    linksSection () {
      return this.footer.find(({ _typename }) => _typename === 'Section')
    },

    bottomSection () {
      return this.footer.find(({ _typename }) => _typename === 'BottomSection')?.elements?.[0]?.items ?? []
    },
  },
}
