function isLeapYear (year) {
  const d = new Date(year, 1, 28)

  d.setDate(d.getDate() + 1)
  return d.getMonth() === 1
}

function getAge (date, now = new Date()) {
  const d = new Date(date)
  let years = now.getFullYear() - d.getFullYear()

  d.setFullYear(d.getFullYear() + years)
  if (d > now) {
    years--
    d.setFullYear(d.getFullYear() - 1)
  }
  const days = (now.getTime() - d.getTime()) / (3600 * 24 * 1000)

  return years + days / (isLeapYear(now.getFullYear()) ? 366 : 365)
}

const ageBetween = {
  params: ['ageFrom', 'ageTo'],
  validate (value, { ageFrom, ageTo }) {
    const age = getAge(value)

    return age >= (parseInt(ageFrom) || 0) && age <= (parseInt(ageTo) || 1000)
  },
}

export { ageBetween, getAge }
