import { dateTime } from '~/services/dateTime/dateTime'

const noFutureDates = {
  validate (date) {
    const currDate = dateTime().toISODate()

    return new Date(currDate) >= new Date(date)
  },
}

export { noFutureDates }
