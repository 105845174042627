

export default {
  name: 'BaseHtmlContent',
  props: {
    htmlContent: {
      type: String,
      default: '',
    },
  },
}
