import { configure } from 'vee-validate'

export default function ({ app }) {
  configure({
    defaultMessage: (field, values) => {
      values._field_ = app.i18n.t(`${field}`)
      return app.i18n.t(`validation.${values._rule_}`, values)
    },
  })

  return {
    silentTranslationWarn: true,
    silentFallbackWarn: true,
    pluralizationRules: {
      pl (choise, choiseLength) {
        if (choise < 2 || choiseLength <= 2) {
          return choise
        }

        if (choise < 5 || choiseLength <= 3) {
          return 2
        }

        return 3
      },
    },
  }
}
