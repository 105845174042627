
export default {
  name: 'BaseTooltipMedi',

  props: {
    content: {
      type: String,
      default: null,
    },
  },
}
