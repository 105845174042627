import Vue from 'vue'
import { camelCaseToKebabCase } from '~/utils/stringTransform'

const getCssClassName = (baseClass, modifier) => `${baseClass}--${modifier}`

/**
 * return bem modifiers based on component root class, given modifiers and modifiers availability
 * @param rootClass
 * @param bemModifiers
 * @param availableBemModifiers
 */
const getBemModifiers = (rootClass, bemModifiers, availableBemModifiers) => {
  const modifiers =
    typeof bemModifiers === 'string' ? [bemModifiers] : bemModifiers

  return modifiers.map((modifier) => {
    if (typeof modifier === 'object') {
      const modifiers = Object.keys(modifier).filter((key) => {
        return modifier[key]
      })

      return getBemModifiers(rootClass, modifiers, availableBemModifiers)
    }

    modifier = camelCaseToKebabCase(modifier)

    if (availableBemModifiers && !availableBemModifiers.includes(modifier)) {
      return undefined
    }

    return getCssClassName(rootClass, modifier)
  })
}

const bemMixin = {
  props: {
    bemModifiers: {
      type: [Array, String],
      default: () => [],
    },
  },

  methods: {
    getBemModifiers (rootClass, modifiers) {
      const baseClass =
        rootClass || camelCaseToKebabCase(this.$options.name)
      const availableBemModifiers =
        this.$options.availableBemModifiers || undefined
      const bemModifiers = modifiers || this.bemModifiers

      return getBemModifiers(
        baseClass,
        bemModifiers,
        availableBemModifiers,
      )
    },
  },
}

const plugin = {
  install: (VueInstance) => {
    VueInstance.mixin(bemMixin)
  },
}

Vue.use(plugin)

export { getBemModifiers }

export default plugin
