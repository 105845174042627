import types from './types'

export default {
  [types.GET_BLOCKS] (state) {
    return (payload) => {
      const blocks = JSON.parse(JSON.stringify(state.blocks))

      return Object.entries(blocks)
        .filter(([key]) => payload.includes(key))
        .reduce((acc, [key, block]) => {
          acc[key] = block
          return acc
        }, {})
    }
  },
}
