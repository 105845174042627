import camelCaseToUnderscore from '~/utils/stringTransform/camelCaseToUnderscore'

function camelCaseKeysToUnderscore (obj) {
  if (typeof obj !== 'object' || obj === null) {
    return obj
  }

  const newObj = Array.isArray(obj) ? [...obj] : { ...obj }

  for (const oldName in newObj) {
    // Check for the old property name to avoid a ReferenceError in strict mode.
    if (Object.prototype.hasOwnProperty.call(newObj, oldName)) {
      // Camel to underscore
      const newName = camelCaseToUnderscore(oldName)

      // Only process if names are different
      if (newName !== oldName) {
        newObj[newName] = newObj[oldName]
        delete newObj[oldName]
      }

      // Recursion
      if (typeof newObj[newName] === 'object') {
        newObj[newName] = camelCaseKeysToUnderscore(newObj[newName])
      }
    }
  }

  return newObj
}

export default obj => camelCaseKeysToUnderscore(obj)
