import urlRewriteQuery from '~/graphql/urlRewrite.graphql'

export default async function ({ app, route, redirect }) {
  const requestPath = route.path
  const queryParams = new URLSearchParams(route.query).toString()

  // console.log('wchodze na', route.fullPath)

  if (route.path.slice(-1) === '/') {
    const url = app.localePath(`${route.path.slice(0, -1)}?${queryParams}`)

    // console.log('robie przekierowanie', url)

    return redirect(301, url)
  } else {
    // console.log('')
  }

  await app.$graphql({
    requestPayload: {
      query: urlRewriteQuery,
      useGETForQueries: true,
      context: {
        fetchOptions: {
          method: 'GET',
        },
      },
      variables: {
        requestPath,
      },
    },
    successHandler: ({ data: { urlRewrite } }) => {
      if (urlRewrite?.targetPath) {
        redirect(301, app.localePath(`${urlRewrite.targetPath}?${queryParams}`))
      }
    },
  })
}
