// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xxs": "320px",
	"xs": "575px",
	"sm": "768px",
	"md": "1024px",
	"lg": "1160px",
	"xl": "1920px"
};
module.exports = ___CSS_LOADER_EXPORT___;
