export default async function ({ app, store, $cookies }) {
  const { isLogged } = store.state.user
  const { from } = app?.context
  const isFromLoginPath = from?.path === '/auth/login' || from?.path === '/checkout/auth'

  if (isLogged && isFromLoginPath) {
    try {
      const {
        salesManagoURL,
        salesManagoClientId: clientId,
        salesManagoApiKey: apiKey,
        salesManagoURLSha: sha,
        salesManagoOwner: owner,
      } = app.$config

      const { email } = store.state.user.profile

      const res = fetch(salesManagoURL, {
        method: 'POST',
        headers: {
          Accept: 'application/json, application/json',
          'Content-Type': 'application/json;charset=UTF-8',
        },
        body: JSON.stringify({
          clientId,
          apiKey,
          requestTime: Date.now(),
          sha,
          email,
          owner,
        }),
      })

      // const res = await fetch('https://jsonplaceholder.typicode.com/todos/1', {
      //   method: 'GET',
      //   headers: {
      //     Accept: 'application/json, text/plain, */*',
      //     'Content-Type': 'application/json',
      //   },
      // })

      //
      const { id } = await res.json()

      // zapisac do cookie i na mounted przepisac do session albo sprawdzic czy cookie dziala w js

      $cookies.set('salesManagoClientId', id, {
        path: '/',
        maxAge: 315360000, // 10 years
      })
    } catch {
    }
  }
}
