
export default {
  name: 'HeaderLogoContent',

  props: {
    desktopOnly: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    withSignet () {
      if (this.desktopOnly) {
        return false
      }

      const storeWithSignet = ['default', 'medicover', 'mindhealth']

      return storeWithSignet.includes(this.$config.storeCode)
    },

    getSignetWidth () {
      switch (this.$config.storeCode) {
        case 'mindhealth':
          return 60
        default:
          return 42
      }
    },

    getHeight () {
      switch (this.$config.storeCode) {
        case 'synevo':
          return 40
        case 'damian':
        case 'medicoverapteka':
        case 'mindhealth':
          return 60
        default:
          return 31
      }
    },
  },
}
