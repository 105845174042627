import types from './types'
import customer from '~/graphql/customer/queries/customer.graphql'

export default {
  logout ({ commit }) {
    this.$cookies.remove('apollo-token', { path: '/' })
    commit(types.SET_LOGGED, false)
    commit(types.SET_PROFILE, {})

    this.$customer?.setCustomer({})
  },

  async getProfile ({ commit, dispatch }) {
    if (!this.$cookies.get('apollo-token')) {
      await dispatch('logout')
      return
    }

    commit(types.SET_LOGIN_PENDING, true)
    await this.$graphql({
      requestPayload: {
        fetchPolicy: 'network-only',
        query: customer,
      },
      successHandler: ({ data }) => {
        const { isMediclubMember, personalData, mediclubCardId, salesManagoId, questionnaireStatuses, createdAt } = data.customer

        commit(types.SET_LOGGED, true)
        commit(types.SET_IS_MEDICLUB_MEMBER, isMediclubMember)
        commit(types.SET_QUESTIONNAIRE_STATUSES, questionnaireStatuses)
        commit(types.SET_PROFILE, { ...personalData, mediclubCardId, salesManagoId, createdAt })

        this.$gtm.push({
          event: 'user_data',
          user_id: salesManagoId,
        })

        dispatch('setLoginCounterCookie')
      },
      errorHandler: async () => {
        await dispatch('logout')
      },
    })

    commit(types.SET_LOGIN_PENDING, false)
  },

  setIsLogin ({ commit }, isLogin) {
    commit(types.SET_LOGGED, isLogin)
  },

  setLoginCounterCookie ({ getters }) {
    const emailCounterCookieName = getters[types.GET_EMAIL_COUNTER_COOKIE_NAME]
    const counter = +this.$cookies.get(emailCounterCookieName) || 0

    this.$cookies.set(emailCounterCookieName, counter + 1, {
      path: '/',
      maxAge: 315360000, // 10 years
    })
  },
}
