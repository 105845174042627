import { createModelFromSchema } from '~/utils/modelSchema/modelSchema'

export const Schema = {
  name: 'Personal data',
  sections: {
    'beneficiary-profile': {
      name: 'Beneficiary data',
      elements: {
        firstname: {
          label: 'Firstname',
          component: 'BaseFormInput',
          rules: 'required|name',
          maxlength: '100',
        },
        lastname: {
          label: 'Lastname',
          component: 'BaseFormInput',
          rules: 'required|name',
          maxlength: '100',
        },
        isForeigner: {
          label: 'Foreigner',
          component: 'BaseFormCheckbox',
          isSizeSmall: true,
        },
        isChild: {
          label: 'Child without CNP',
          component: 'BaseFormCheckbox',
          isSizeSmall: true,
        },
        govId: {
          label: 'CNP number',
          component: 'BaseFormInput',
          rules: 'cnp',
          maxlength: '13',
          showElementWhen: [
            {
              model: 'isForeigner',
              equals: false,
            },
            {
              model: 'isChild',
              equals: false,
            },
          ],
        },
        passport: {
          // @todo walidator dla paszportu
          label: 'Passport number',
          component: 'BaseFormInput',
          maxlength: '100',
          showElementWhen: [
            {
              model: 'isForeigner',
              equals: true,
            },
            {
              model: 'isChild',
              equals: false,
            },
          ],
        },
        govIdGuardian: {
          label: 'Cnp number of the legal guardian',
          component: 'BaseFormInput',
          rules: 'cnp',
          maxlength: '13',
          showElementWhen: [
            {
              model: 'isForeigner',
              equals: false,
            },
            {
              model: 'isChild',
              equals: true,
            },
          ],
        },
        passportMother: {
          // @todo walidator dla paszportu
          label: 'Passport number of the legal guardian',
          component: 'BaseFormInput',
          maxlength: '100',
          showElementWhen: [
            {
              model: 'isForeigner',
              equals: true,
            },
            {
              model: 'isChild',
              equals: true,
            },
          ],
        },
        gender: {
          label: 'Gender',
          component: 'BaseFormSelect',
          items: [
            {
              value: 1,
              text: 'Male',
            },
            {
              value: 2,
              text: 'Female',
            },
          ],
        },
        dateOfBirth: {
          label: 'Date of birth',
          component: 'BaseFormDatepicker',
          rules: 'noFutureDates',
        },
        email: {
          label: 'Email',
          hint: 'The e-mail address provided is used to send you alerts about test results and appointment reminders. This can be an individual address or, in the case of minors, the address of their legal guardian.',
          component: 'BaseFormInput',
          rules: 'required|email',
          maxlength: '255',
        },
        phone: {
          label: 'Phone',
          hint: 'Avem nevoie de numărul dvs. de telefon pentru a verifica comenzi și pentru a vã contacta în legătură cu vizitele programate.',
          component: 'BaseFormPhone',
          rules: 'required',
          maxlength: '20',
        },
      },
    },
    'customer-address': {
      name: 'Residence address',
      elements: {
        addressStreet: {
          label: 'Street',
          component: 'BaseFormInput',
          rules: 'required',
          maxlength: '255',
        },
        addressHouseNumber: {
          label: 'House number',
          component: 'BaseFormInput',
          fieldBemModifiers: ['half'],
          rules: 'required',
          maxlength: '20',
        },
        addressApartmentNumber: {
          label: 'Apartment number',
          component: 'BaseFormInput',
          fieldBemModifiers: ['half'],
          maxlength: '20',
        },
        addressCity: {
          label: 'City',
          component: 'BaseFormInput',
          rules: 'required',
          maxlength: '100',
        },
        addressPostcode: {
          label: 'Postcode',
          component: 'BaseFormInput',
          fieldBemModifiers: ['half'],
          rules: 'required|post_code_ro',
          mask: '######',
          maskPlaceholder: '______',
        },
      },
    },
    'customer-mailing': {
      name: 'Mailing address',
      elements: {
        isOtherAddress: {
          label: 'Other address',
          component: 'BaseFormCheckbox',
          isSizeSmall: true,
        },
        mailingStreet: {
          label: 'Street',
          component: 'BaseFormInput',
          rules: 'required',
          maxlength: '255',
          showElementWhen: [
            {
              model: 'isOtherAddress',
              equals: true,
            },
          ],
        },
        mailingHouseNumber: {
          label: 'House number',
          component: 'BaseFormInput',
          rules: 'required',
          fieldBemModifiers: ['half'],
          maxlength: '20',
          showElementWhen: [
            {
              model: 'isOtherAddress',
              equals: true,
            },
          ],
        },
        mailingApartmentNumber: {
          label: 'Apartment number',
          component: 'BaseFormInput',
          fieldBemModifiers: ['half'],
          maxlength: '20',
          showElementWhen: [
            {
              model: 'isOtherAddress',
              equals: true,
            },
          ],
        },
        mailingCity: {
          label: 'City',
          component: 'BaseFormInput',
          rules: 'required',
          maxlength: '100',
          showElementWhen: [
            {
              model: 'isOtherAddress',
              equals: true,
            },
          ],
        },
        mailingPostcode: {
          label: 'Postcode',
          component: 'BaseFormInput',
          fieldBemModifiers: ['half'],
          rules: 'required|post_code_ro',
          mask: '######',
          maskPlaceholder: '______',
          showElementWhen: [
            {
              model: 'isOtherAddress',
              equals: true,
            },
          ],
        },
      },
    },
  },
  watch: [
    { govId: 'govId' },
    { isForeigner: 'isChildOrForeigner' },
    { isChild: 'isChildOrForeigner' },
    { isOtherAddress: 'isOtherAddress' },
  ],
}

export const Model = createModelFromSchema(Schema)
