import { render, staticRenderFns } from "./FooterLocale.vue?vue&type=template&id=cf439ada&scoped=true&"
var script = {}
import style0 from "./FooterLocale.vue?vue&type=style&index=0&id=cf439ada&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cf439ada",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FooterHeading: require('/home/gitlab-runner/builds/Dz_K4MD8/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/footer/V2/FooterHeading.vue').default,BaseFlag: require('/home/gitlab-runner/builds/Dz_K4MD8/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/base/BaseFlag.vue').default})
