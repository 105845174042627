export default {
  GET_IS_MEDICLUB_MEMBER: 'GET_IS_MEDICLUB_MEMBER',
  GET_EMAIL_COUNTER_COOKIE_NAME: 'GET_EMAIL_COUNTER_COOKIE_NAME',
  GET_SALESMANAGO_ID: 'GET_SALESMANAGO_ID',
  GET_IS_FOREIGNER: 'GET_IS_FOREIGNER',
  SET_LOGGED: 'SET_LOGGED',
  SET_PROFILE: 'SET_PROFILE',
  SET_LOGIN_PENDING: 'SET_LOGIN_PENDING',
  SET_CUSTOMER_AGREEMENTS: 'SET_CUSTOMER_AGREEMENTS',
  SET_IS_MEDICLUB_MEMBER: 'SET_IS_MEDICLUB_MEMBER',
  SET_QUESTIONNAIRE_STATUSES: 'SET_QUESTIONNAIRE_STATUSES',
}
