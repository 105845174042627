
import { createNamespacedHelpers } from 'vuex'
import { isEmpty } from '~/utils/Utils'
import staticTypes from '~/store/staticBlock/types'

const {
  mapGetters: mapGettersStatic,
} = createNamespacedHelpers('staticBlock')

export default {
  name: 'BaseStaticBlock',
  props: {
    identifiers: {
      type: Array,
      required: true,
    },
  },

  async fetch () {
    if (!this.haveItems) {
      await this.$store.dispatch('staticBlock/getByIdentifiers', this.identifiers)
    }
  },

  computed: {
    ...mapGettersStatic({
      getBlocks: staticTypes.GET_BLOCKS,
    }),

    items () {
      return this.getBlocks(this.identifiers)
    },

    htmlItems () {
      return Object.fromEntries(Object.entries(this.items).filter(([key, value]) => !value.isJson))
    },

    haveItems () {
      return !isEmpty(this.items)
    },
  },
}
