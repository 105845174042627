
export default {
  name: 'HeaderButtonCart',

  provide () {
    return {
      headerCart: this.headerCart,
    }
  },

  data () {
    return {
      headerCart: {
        isOpen: false,
      },
    }
  },

  computed: {
    visible () {
      return this.$css.breakpoints.mdAndUp
    },
  },

  mounted () {
    this.$root.$on('global-open-mini-cart', this.openFromGlobal)
  },

  beforeDestroy () {
    this.$root.$off('global-open-mini-cart', this.openFromGlobal)
  },

  methods: {
    openFromGlobal () {
      if (!this.visible) {
        return
      }

      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })

      this.openMenu()
    },

    openMenu () {
      if (!this.visible) {
        return
      }

      this.headerCart.isOpen = true
    },

    closeMenu () {
      this.headerCart.isOpen = false
    },
  },
}
