export function dateTime (...props) {
  let date = new Date(...props)

  date.toISODate = function () {
    const y = date.getFullYear()
    const m = date.getMonth() + 1
    const d = date.getDate()

    return `${y}-${m.toString().padStart(2, 0)}-${d.toString().padStart(2, 0)}`
  }

  date.addDays = function (daysToAdd) {
    date = new Date(date.setDate(date.getDate() + daysToAdd))
    return this
  }

  date.subtractDays = function (daysToSubtract) {
    date = new Date(date.setDate(date.getDate() - daysToSubtract))
    return this
  }

  return date
}

export function formatDate (locale = 'en-EN') {
  function dateTimeFormat (format, date) {
    return new Intl.DateTimeFormat(locale, format).format(date)
  }

  return {
    getNumericDate (date) {
      return dateTimeFormat({ day: 'numeric', month: 'numeric', year: '2-digit' }, date)
    },

    getNumericDateFullYear (date) {
      return dateTimeFormat({ day: 'numeric', month: 'numeric', year: 'numeric' }, date)
    },

    getWeekday (date) {
      return dateTimeFormat({ weekday: 'long' }, date)
    },

    getWeekdayWithDate (date) {
      return dateTimeFormat({ weekday: 'short', day: 'numeric', month: 'numeric' }, date)
    },

    getHour (date) {
      return dateTimeFormat({ hour: 'numeric', minute: 'numeric' }, date)
    },

    getLongDate (date) {
      return dateTimeFormat({ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }, date)
    },

    getDateWithStringMonth (date) {
      return dateTimeFormat({ year: 'numeric', month: 'long', day: 'numeric' }, date)
    },

    getFullDateAndTime (date) {
      return dateTimeFormat({ day: 'numeric', month: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' }, date)
    },
  }
}
