
export default {
  name: 'BaseButton',
  props: {
    type: {
      type: String,
      default: 'submit',
    },

    to: {
      type: [String, Object],
      default: undefined,
    },

    href: {
      type: String,
      default: undefined,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    hasLoader: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    component () {
      return this.to ? 'nuxt-link' : this.href ? 'a' : 'button'
    },

    bindComponentProps () {
      // seo sie czepia jak sa zle propsy (np type dla <a>)
      return this.to
        ? { // nuxt-link
            to: this.to,
          }
        : this.href
          ? { // a
              href: this.href,
            }
          : { // button
              disabled: this.disabled,
              type: this.type,
            }
    },
  },
}
