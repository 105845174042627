
import Toast from '@/mixins/toast'

export default {
  name: 'HeaderToast',

  mixins: [Toast],

  data () {
    return {
      place: this.$toast.places.header,
    }
  },
}
