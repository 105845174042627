import { createModelFromSchema } from '~/utils/modelSchema/modelSchema'

export const Schema = {
  name: 'Personal data',
  sections: {
    'beneficiary-profile': {
      name: 'Beneficiary data',
      elements: {
        firstname: {
          label: 'Firstname',
          component: 'BaseFormInput',
          rules: 'required|name',
          maxlength: '100',
        },
        lastname: {
          label: 'Lastname',
          component: 'BaseFormInput',
          rules: 'required|name',
          maxlength: '100',
        },
        isForeigner: {
          label: 'Foreigner',
          component: 'BaseFormCheckbox',
          isSizeSmall: true,
          showElementWhen: [
            {
              model: 'hasOnlyTrainingProductsFromRehasport',
              equals: false,
            },
          ],
        },
        isChild: {
          label: 'Child without PESEL',
          component: 'BaseFormCheckbox',
          isSizeSmall: true,
          showElementWhen: [
            {
              model: 'hasOnlyTrainingProductsFromRehasport',
              equals: false,
            },
          ],
        },
        govId: {
          label: 'Pesel number',
          component: 'BaseFormInput',
          rules: 'pesel',
          maxlength: '11',
          showElementWhen: [
            {
              model: 'isForeigner',
              equals: false,
            },
            {
              model: 'isChild',
              equals: false,
            },
            {
              model: 'hasOnlyTrainingProductsFromRehasport',
              equals: false,
            },
          ],
        },
        passport: {
          // @todo walidator dla paszportu
          label: 'Passport number',
          component: 'BaseFormInput',
          maxlength: '100',
          showElementWhen: [
            {
              model: 'isForeigner',
              equals: true,
            },
            {
              model: 'isChild',
              equals: false,
            },
            {
              model: 'hasOnlyTrainingProductsFromRehasport',
              equals: false,
            },
          ],
        },
        govIdGuardian: {
          label: 'PESEL number of the legal guardian',
          component: 'BaseFormInput',
          rules: 'pesel',
          maxlength: '11',
          showElementWhen: [
            {
              model: 'isForeigner',
              equals: false,
            },
            {
              model: 'isChild',
              equals: true,
            },
            {
              model: 'hasOnlyTrainingProductsFromRehasport',
              equals: false,
            },
          ],
        },
        passportMother: {
          // @todo walidator dla paszportu
          label: 'Passport number of the legal guardian',
          component: 'BaseFormInput',
          maxlength: '100',
          showElementWhen: [
            {
              model: 'isForeigner',
              equals: true,
            },
            {
              model: 'isChild',
              equals: true,
            },
            {
              model: 'hasOnlyTrainingProductsFromRehasport',
              equals: false,
            },
          ],
        },
        gender: {
          label: 'Gender',
          component: 'BaseFormSelect',
          items: [
            {
              value: 1,
              text: 'Male',
            },
            {
              value: 2,
              text: 'Female',
            },
          ],
        },
        dateOfBirth: {
          label: 'Date of birth',
          component: 'BaseFormDatepicker',
          rules: 'noFutureDates',
          showElementWhen: [
            {
              model: 'hasOnlyTrainingProductsFromRehasport',
              equals: false,
            },
          ],
        },
        email: {
          label: 'Email',
          hint: 'Podany adres e-mail służy do przesyłania alertów o wynikach badań oraz przypomnień o umówionych wizytach. Może być to indywidualny adres lub w przypadku osób małoletnich - adres ich prawnego opiekuna.',
          component: 'BaseFormInput',
          rules: 'required|email',
          maxlength: '255',
        },
        phone: {
          label: 'Phone',
          hint: 'Potrzebujemy Twojego numeru telefonu do weryfikacji zamówień oraz kontaktu ws. umówionych wizyt.',
          component: 'BaseFormPhone',
          rules: 'required',
          maxlength: '20',
        },
      },
    },
    'customer-address': {
      name: 'Residence address',
      elements: {
        addressStreet: {
          label: 'Street',
          component: 'BaseFormInput',
          rules: 'required',
          maxlength: '255',
          showElementWhen: [
            {
              model: 'hasOnlyTrainingProductsFromRehasport',
              equals: false,
            },
          ],
        },
        addressHouseNumber: {
          label: 'House number',
          component: 'BaseFormInput',
          fieldBemModifiers: ['half'],
          rules: 'required',
          maxlength: '20',
          showElementWhen: [
            {
              model: 'hasOnlyTrainingProductsFromRehasport',
              equals: false,
            },
          ],
        },
        addressApartmentNumber: {
          label: 'Apartment number',
          component: 'BaseFormInput',
          fieldBemModifiers: ['half'],
          maxlength: '20',
          showElementWhen: [
            {
              model: 'hasOnlyTrainingProductsFromRehasport',
              equals: false,
            },
          ],
        },
        addressCity: {
          label: 'City',
          component: 'BaseFormInput',
          rules: 'required',
          maxlength: '100',
          showElementWhen: [
            {
              model: 'hasOnlyTrainingProductsFromRehasport',
              equals: false,
            },
          ],
        },
        addressPostcode: {
          label: 'Postcode',
          component: 'BaseFormInput',
          fieldBemModifiers: ['half'],
          rules: 'required|post_code',
          mask: '##-###',
          maskPlaceholder: '__-___',
          showElementWhen: [
            {
              model: 'hasOnlyTrainingProductsFromRehasport',
              equals: false,
            },
          ],
        },
      },
      showSectionWhen: [
        {
          model: 'hasOnlyTrainingProductsFromRehasport',
          equals: false,
        },
      ],
    },
    'customer-mailing': {
      name: 'Mailing address',
      elements: {
        isOtherAddress: {
          label: 'Other address',
          component: 'BaseFormCheckbox',
          isSizeSmall: true,
          showElementWhen: [
            {
              model: 'hasOnlyTrainingProductsFromRehasport',
              equals: false,
            },
          ],
        },
        mailingStreet: {
          label: 'Street',
          component: 'BaseFormInput',
          rules: 'required',
          maxlength: '255',
          showElementWhen: [
            {
              model: 'isOtherAddress',
              equals: true,
            },
            {
              model: 'hasOnlyTrainingProductsFromRehasport',
              equals: false,
            },
          ],
        },
        mailingHouseNumber: {
          label: 'House number',
          component: 'BaseFormInput',
          rules: 'required',
          fieldBemModifiers: ['half'],
          maxlength: '20',
          showElementWhen: [
            {
              model: 'isOtherAddress',
              equals: true,
            },
            {
              model: 'hasOnlyTrainingProductsFromRehasport',
              equals: false,
            },
          ],
        },
        mailingApartmentNumber: {
          label: 'Apartment number',
          component: 'BaseFormInput',
          fieldBemModifiers: ['half'],
          maxlength: '20',
          showElementWhen: [
            {
              model: 'isOtherAddress',
              equals: true,
            },
            {
              model: 'hasOnlyTrainingProductsFromRehasport',
              equals: false,
            },
          ],
        },
        mailingCity: {
          label: 'City',
          component: 'BaseFormInput',
          rules: 'required',
          maxlength: '100',
          showElementWhen: [
            {
              model: 'isOtherAddress',
              equals: true,
            },
            {
              model: 'hasOnlyTrainingProductsFromRehasport',
              equals: false,
            },
          ],
        },
        mailingPostcode: {
          label: 'Postcode',
          component: 'BaseFormInput',
          fieldBemModifiers: ['half'],
          rules: 'required|post_code',
          mask: '##-###',
          maskPlaceholder: '__-___',
          showElementWhen: [
            {
              model: 'isOtherAddress',
              equals: true,
            },
            {
              model: 'hasOnlyTrainingProductsFromRehasport',
              equals: false,
            },
          ],
        },
      },
      showSectionWhen: [
        {
          model: 'hasOnlyTrainingProductsFromRehasport',
          equals: false,
        },
      ],
    },
  },
  watch: [
    { govId: 'govId' },
    { isForeigner: 'isChildOrForeigner' },
    { isChild: 'isChildOrForeigner' },
    { isOtherAddress: 'isOtherAddress' },
  ],
}

export const Model = createModelFromSchema(Schema)
