
export default {
  name: 'FooterScrollToTop',

  props: {
    isMegaMenuAvailable: {
      type: Boolean,
      default: false,
    },

    isReservationTimerAvailable: {
      type: Object,
      default: () => {},
    },
  },

  data: () => ({
    isVisible: false,
  }),

  mounted () {
    window.addEventListener('scroll', this.onScroll)
  },

  beforeDestroy () {
    window.removeEventListener('scroll', this.onScroll)
  },

  methods: {
    onScroll () {
      this.isVisible = window.scrollY > 300
    },

    onClick () {
      window.scroll({ top: 0, behavior: 'smooth' })
    },
  },
}
