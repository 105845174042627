import underscoreKeysToCamelCase from '~/utils/stringTransform/underscoreKeysToCamelCase'
import { cacheGet, cacheSet } from '~/services/cache/cache'

class AuthError extends Error {
  constructor (message, graphQLErrors) {
    super(message)
    this.name = 'AuthError'
    this.graphQLErrors = graphQLErrors
  }
}

class GraphqlError extends Error {
  constructor (message = 'Server error', graphQLErrors) {
    super(message)
    this.name = 'GraphqlError'
    this.graphQLErrors = graphQLErrors
  }
}

export default ({ app, $config }, inject) => {
  const graphql = async ({
    requestPayload,
    signal,
    successHandler,
    errorHandler,
    abortHandler,
    loaderTarget,
    loaderTargetBemModifiers = [],
    addLoader = false,
  } = {}) => {
    let loaderInstance
    const { $loader, $toast } = app

    if (process.client && addLoader) {
      loaderInstance = await $loader.add({
        targetNode: loaderTarget && loaderTarget.$el ? loaderTarget.$el : loaderTarget,
        bemModifiers: loaderTargetBemModifiers,
      })
    }

    try {
      let reqUrl = `${$config.apiUrl}/graphql`
      const method = requestPayload.method ?? requestPayload.context?.fetchOptions?.method ?? 'POST'
      const fetchPolicy = requestPayload.fetchPolicy ?? 'cache-first'

      const opts = {
        method,
        headers: {
          ...requestPayload.context?.headers,
          'Content-Type': 'application/json',
          Store: $config.storeCode === 'default' && app.i18n.locale === 'en'
            ? 'default'
            : `${$config.storeCode}_${app.i18n.locale}`,
        },
      }

      if (signal) {
        opts.signal = signal
      }

      if (app.$cookies.get('apollo-token')) {
        opts.headers.Authorization = `Bearer ${app.$cookies.get('apollo-token')}`
      }

      const query = requestPayload.query instanceof Promise ? await requestPayload.query : requestPayload.query
      const mutation = requestPayload.mutation instanceof Promise ? await requestPayload.mutation : requestPayload.mutation
      const isMutation = !!mutation

      const body = {
        query: query ?? mutation,
      }

      if (!body.query) {
        // czy potrzebny jest blad jezeli nie przeslano query/mutacji? throw...
        return
      }

      if (method === 'POST') {
        if (requestPayload.variables) {
          body.variables = requestPayload.variables
        }
        opts.body = JSON.stringify(body)
      }

      if (method === 'GET') {
        if (requestPayload.variables) {
          body.variables = JSON.stringify(requestPayload.variables)
        }

        reqUrl += `?${new URLSearchParams(body)}`
      }

      if (!isMutation && fetchPolicy === 'cache-first') {
        const data = cacheGet({ reqUrl, opts })

        if (data) {
          if (successHandler) {
            await successHandler(data)
          }

          return
        }
      }

      const req = await fetch(reqUrl, opts)
      const json = await req.json()

      if (json.errors) {
        const errPolicy = requestPayload.errorPolicy ?? 'none'
        const authError = json.errors.find(err => err.extensions.category === 'graphql-authentication')

        if (authError) {
          throw new AuthError(authError.message, [authError])
        }

        if (errPolicy === 'none') {
          throw new GraphqlError(json.errors[0]?.message.replace('GraphQL error:', '').trim(), json.errors)
        }

        if (errPolicy === 'ignore') {
          delete json.errors
        }
      }

      const data = underscoreKeysToCamelCase(json)

      if (!isMutation && fetchPolicy === 'cache-first' && process.client) {
        cacheSet({ reqUrl, opts }, data)
      }

      if (successHandler) {
        await successHandler(data)
      }
    } catch (error) {
      if (error?.name === 'SyntaxError' || error?.name === 'TypeError') {
        // eslint-disable-next-line no-console
        return console.error(error)
      }

      if (error?.name === 'AbortError') {
        return abortHandler?.()
      }

      if (error?.name === 'AuthError') {
        app.store.dispatch('user/logout')

        if (process.client) {
          app.router.push({ path: '/auth/login/' })
        }
      }

      if (errorHandler) {
        await errorHandler(error)
      } else {
        $toast.add({
          text: error.message,
          type: 'error',

          place: error?.name === 'AuthError'
            ? $toast.places.header
            : $toast.places.default,
        })
      }

      // eslint-disable-next-line no-console
      console.warn('GraphqlRequest error:', error.message)
    }

    if (process.client && loaderInstance) {
      $loader.remove({ instance: loaderInstance })
    }
  }

  inject('graphql', graphql)
}
