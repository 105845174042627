export default {
  data () {
    return {
      keepAliveProps: {
        include: [
          'Mediclub',
          'MediclubPage',
          'CmsMediclub*',
        ],
      },
    }
  },
}
