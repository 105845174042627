const outOfRange = {
  params: ['itemsCount', 'activePage'],
  validate (value, { itemsCount }) {
    if (+itemsCount <= 0 || +value <= 0 || isNaN(+value)) {
      return false
    }
    return +itemsCount >= +value
  },
}

export { outOfRange }
