
import { createNamespacedHelpers } from 'vuex'
import { isEmpty } from '~/utils/Utils'
import configTypes from '~/store/storeConfig/types'

const {
  mapState: mapStateUser,
} = createNamespacedHelpers('user')

const {
  mapGetters: mapGettersConfig,
} = createNamespacedHelpers('storeConfig')

export default {
  name: 'HeaderAccount',
  computed: {
    ...mapStateUser({
      isLogged: state => state.isLogged,
      profile: state => state.profile,
    }),

    ...mapGettersConfig({
      isMegaMenuAvailable: configTypes.IS_MEGAMENU_AVAILABLE,
    }),

    isProfileLoaded () {
      return !isEmpty(this.profile)
    },
  },

  watch: {
    '$route.fullPath': {
      immediate: true,
      handler () {
        this.hideUserAdmission()
      },
    },
  },

  methods: {
    hideUserAdmission () {
      // eslint-disable-next-line prefer-regex-literals
      const regex = new RegExp(`/checkout/step${this.$cart.getCurrentStep()}`)

      return regex.test(this.$route?.matched[1]?.path)
    },
  },
}
