class ExpiringStorage {
  get (key, defaultValue = null) {
    try {
      const storageKey = `storage-${key}`
      const cached = JSON.parse(this.getStorageData(storageKey))
      const expires = new Date(cached.expires)

      if (expires && expires < new Date()) {
        this.remove(storageKey)
        return defaultValue
      }

      return cached.value
    } catch {
      return defaultValue
    }
  }

  set (key, value, lifeTimeInMinutes) {
    try {
      const storageKey = `storage-${key}`

      if (lifeTimeInMinutes) {
        const currentTime = new Date().getTime()
        const expires = new Date(currentTime + lifeTimeInMinutes * 60000)

        localStorage.setItem(storageKey, JSON.stringify({
          value,
          expires,
        }))
      } else {
        sessionStorage.setItem(storageKey, JSON.stringify({ value }))
      }
    } catch {}
  }

  remove (key) {
    try {
      const cached = JSON.parse(this.getStorageData(`storage-${key}`))

      if (cached?.expires) {
        localStorage.removeItem(key)
      } else {
        sessionStorage.removeItem(key)
      }
    } catch {}
  }

  getStorageData (storageKey) {
    try {
      return sessionStorage.getItem(storageKey) || localStorage.getItem(storageKey)
    } catch {
      return null
    }
  }
}

export default new ExpiringStorage()
