import { getAge } from '@/utils/formValidators/ageBetween'

const isOfAge = {
  validate (date) {
    const ofAge = 18

    return getAge(date) >= ofAge
  },
}

export { isOfAge }
