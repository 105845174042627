export default [
  {
    text: 'customer.Mediclub zone',
    value: '/mediclub',
    header: true, // czy pokazac element w headerze
    customerPage: true, // czy pokazac element na stronie customera
  },
  {
    text: 'customer.Mediclub card',
    value: '/customer/card',
    header: true,
    customerPage: true,
  },
  {
    text: 'customer.Orders',
    value: '/customer/orders',
    header: false,
    customerPage: true,
  },
  {
    text: 'customer.Your Account',
    value: '/customer/orders',
    header: true,
    customerPage: false,
  },
  {
    text: 'customer.Your data',
    value: '/customer/edit',
    header: false,
    customerPage: true,
  },
  {
    text: 'customer.Beneficiaries',
    value: '/customer/beneficiaries',
    header: false,
    customerPage: true,
  },
  {
    text: 'customer.Your agreements',
    value: '/customer/agreements',
    header: false,
    customerPage: true,
  },
  {
    text: 'customer.Preference survey',
    value: '/customer/questionnaire/participants',
    header: true,
    customerPage: true,
  },
  {
    text: 'customer.Mental Health Survey',
    value: '/customer/questionnaire/mental-health?agreements',
    header: true,
    customerPage: true,
  },
  {
    text: 'Logout',
    value: '/auth/logout',
    icon: 'logout',
    header: true,
    customerPage: true,
  },
]
