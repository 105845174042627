import types from './types'
import cmsBlocks from '~/graphql/cmsBlocks/cmsBlocks.graphql'

export default {
  setAvailableIdentifiers ({ commit }, payload) {
    commit(types.SET_AVAILABLE_IDENTIFIERS, payload?.items.map(({ identifier }) => identifier) || [])
  },

  async getByIdentifiers ({ state, commit }, payload) {
    const { availableIds, unavailableIds } = payload.reduce((acc, identifier) => {
      state.availableIdentifiers.includes(identifier)
        ? acc.availableIds.push(identifier)
        : acc.unavailableIds.push(identifier)

      return acc
    }, { availableIds: [], unavailableIds: [] })

    if (unavailableIds.length) {
      // eslint-disable-next-line no-console
      console.warn('UNAVAILABLE STATIC BLOCKS:', unavailableIds.join(', '))
    }

    if (!availableIds.length) {
      return
    }

    await this.$graphql({
      requestPayload: {
        query: cmsBlocks,
        errorPolicy: 'all',
        variables: {
          identifiers: availableIds,
        },
        context: {
          fetchOptions: {
            method: 'GET',
          },
        },
      },
      successHandler: ({ data }) => {
        const items = data.cmsBlocks.items.reduce((acc, item) => {
          if (item) {
            try {
              item.contentJson = JSON.parse(item.content)
              item.isJson = true
            } catch (e) {
              item.isJson = false
              // eslint-disable-next-line no-console
              console.warn(`STATIC BLOCK JSON PARSE ERROR: ${item.identifier}`)
            }
            acc[item.identifier] = item
          }

          return acc
        }, {})

        commit(types.SET_BLOCKS, items)
      },
      errorHandler: (errorData) => {
        // eslint-disable-next-line no-console
        console.warn(errorData)
      },
    })
  },
}
