
import { createNamespacedHelpers } from 'vuex'
import userTypes from '~/store/user/types'
import expiringStorage from '~/utils/expiringStorage'

const {
  mapGetters: mapGettersUser,
  mapState: mapStateUser,
} = createNamespacedHelpers('user')

export default {
  name: 'HeaderMediclubSurveyBanner',
  inject: ['modal'],
  props: {
    modalId: {
      type: String,
      default: '',
    },
  },

  data: () => ({
    show: false,
    surveys: [
      {
        id: 1,
        code: 'main_questionnaire',
        url: '/customer/questionnaire/participants',
      },
      {
        id: 2,
        code: 'mental_health_questionnaire',
        url: '/customer/questionnaire/mental-health?agreements',
      },

      // {
      //   id: 3,
      //   code: 'fizyczna',
      //   url: 'todo',
      // },
    ],
  }),

  computed: {
    ...mapGettersUser({
      emailCouterCookieName: userTypes.GET_EMAIL_COUNTER_COOKIE_NAME,
    }),

    ...mapStateUser(['isLogged', 'profile', 'questionnaireStatuses']),

    showModal () {
      return this.$config.hasMediclub && this.show
    },

    isCartPending () {
      return !this.$store.state.checkout.cartLoaded
    },

    allSurveysCompleted () {
      return this.questionnaireStatuses.every(({ isCompleted }) => isCompleted)
    },
  },

  watch: {
    isLogged (logged) {
      this.show = logged
    },

    async isCartPending (pending) {
      if (this.showModal && this.isLogged && !pending) {
        const count = +this.$cookies.get(this.emailCouterCookieName) || 0
        const key = `storage-checkout-register-redirect-${this.profile.email}`

        if (count <= 5 && !this.allSurveysCompleted && !this.isLocalStorageKeyExists(key)) {
          try {
            const HeaderMediclubSurveyBanner = await import('~/components/Header/HeaderMediclubSurveyBanner.vue')
            const modalInstance = await this.modal()

            modalInstance.openModal(HeaderMediclubSurveyBanner.default, {}, { width: [{ mobile: '100%', desktop: '100%' }] })
          } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error)
          }
        }
      }
    },
  },

  methods: {
    isSurveyCompleted (surveyCode) {
      const survey = this.questionnaireStatuses.find(({ code }) => code === surveyCode)

      return survey?.isCompleted ?? true
    },

    isLocalStorageKeyExists (key) {
      return !!expiringStorage.getStorageData(key)
    },

    async onHide () {
      const modalInstance = await this.modal()

      modalInstance.closeModal(this.modalId)
    },
  },
}
