import Vue from 'vue'

const Observer = new IntersectionObserver(
  (entries, animateOnScrollObserver) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.dispatchEvent(new Event('in-viewport'))
        animateOnScrollObserver.unobserve(entry.target)
      }
    })
  },
)

Vue.directive('in-viewport', {
  bind: (el) => {
    Observer.observe(el)
  },
})
