const name = {
  validate (value) {
    return (
      !(/[ ]{2,}/.test(value)) &&
      !(/[-]{2,}/.test(value)) &&
      /^(?=.*[A-Za-zŻŹĆĄŚĘŁÓŃżźćńółęąś].*)([A-Za-zŻŹĆĄŚĘŁÓŃżźćńółęąś\- ]+)$/.test(value)
    )
  },
}

export { name }
