
export default {
  name: 'BaseProgressBar',
  props: {
    text: {
      type: String,
      default: '',
    },
  },

  data () {
    return {
      progressValue: 0,
    }
  },

  computed: {
    progressBarWidth () {
      return `${this.progressValue}%`
    },
  },

  mounted () {
    this.increaseProgress()
  },

  methods: {
    increaseProgress () {
      this.progressValue += 1

      if (this.progressValue < 95) {
        setTimeout(this.increaseProgress, 10)
      }
    },
  },
}
