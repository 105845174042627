
import debounce from 'debounce'
import { createNamespacedHelpers } from 'vuex'
import configTypes from '~/store/storeConfig/types'
import headerScroll from '@/mixins/headerScroll'

const {
  mapGetters: mapGettersConfig,
  mapState: mapStateConfig,
} = createNamespacedHelpers('storeConfig')

export default {
  name: 'TheHeaderV2',

  mixins: [
    headerScroll,
  ],

  data () {
    return {
      isNavbarOpen: false,
    }
  },

  computed: {
    ...mapGettersConfig({
      categories: configTypes.GET_CATEGORIES,
      isMegaMenuAvailable: configTypes.IS_MEGAMENU_AVAILABLE,
    }),

    ...mapStateConfig({
      hasNewLook: state => state.hasNewLook,
    }),
  },

  methods: {
    setNavbarStatus: debounce(function (value) {
      this.isNavbarOpen = value
    }, 10),

    onToggle () {
      this.setNavbarStatus(!this.isNavbarOpen)
    },

    onClose () {
      this.setNavbarStatus(false)
    },
  },
}
