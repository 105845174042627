import types from './types'

export default {
  setBreadcrumbs ({ commit }, payload = {}) {
    const { breadcrumbs, name, startPath = '/c' } = payload

    const mapped = [
      { to: '/', text: this.$config.storeName },

      ...breadcrumbs
        ?.filter(crumb => crumb.categoryUrlPath)
        .map(crumb => ({
          to: `${startPath}/${crumb.categoryUrlPath}`,
          text: crumb.categoryName,
        })) || [],

      {
        text: name,
      },
    ]

    commit(types.SET_BREADCRUMBS, mapped)
  },

  setCustomBreadcrumbs ({ commit }, payload = {}) {
    const { breadcrumbs, name } = payload

    const mapped = [
      { to: '/', text: this.$config.storeName },
      ...breadcrumbs,
      {
        text: name,
      },
    ]

    commit(types.SET_BREADCRUMBS, mapped)
  },

  setVirtualBreadcrumbs ({ commit }, payload = {}) {
    const { breadcrumbs, name, urlKey, city } = payload

    const mapped = [
      { to: '/', text: this.$config.storeName },

      ...breadcrumbs
        ?.filter(crumb => crumb.categoryUrlPath)
        .map(crumb => ({
          to: `/c/${crumb.categoryUrlPath}`,
          text: crumb.categoryName,
        })) || [],

      {
        to: `/p/${urlKey}`,
        text: name,
      },
      {
        text: city,
      },
    ]

    commit(types.SET_BREADCRUMBS, mapped)
  },
}
