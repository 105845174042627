export default {
  data () {
    return {
      messages: [],
    }
  },

  beforeDestroy () {
    this.$toast.unsubscribe(this.place || this.$toast.places.default, this.subscribe)
  },

  created () {
    if (process.browser) {
      this.$toast.subscribe(this.place || this.$toast.places.default, this.subscribe)
    }
  },

  methods: {
    onExpired (messageId) {
      this.$toast.remove(messageId)
    },

    subscribe (data) {
      this.messages = data
    },
  },
}
