import types from './types'

export default {
  [types.SET_AVAILABLE_IDENTIFIERS] (state, payload) {
    state.availableIdentifiers = payload
  },

  [types.SET_BLOCKS] (state, payload) {
    state.blocks = { ...state.blocks, ...payload }
  },
}
