import Vue from 'vue'
import { createPopper } from '@popperjs/core'
import BaseTooltipMedi from '~/components/base/tooltip/BaseTooltipMedi'

const BaseTooltipMediComponent = Vue.extend(BaseTooltipMedi)
const instances = {}
let tooltipId = 0

const createTooltip = (el, context) => {
  const dataId = el.dataset.mediTooltip
  let tooltipInstance, tooltip

  if (typeof context.value === 'string') {
    tooltipInstance = new BaseTooltipMediComponent({
      propsData: {
        content: context.value,
      },
    }).$mount()

    tooltip = tooltipInstance.$el
  } else if (context.value.content && !context.value.el) {
    tooltipInstance = new BaseTooltipMediComponent({
      propsData: {
        content: context.value.content,
        bemModifiers: context.value.bemModifiers,
      },
    }).$mount()

    tooltip = tooltipInstance.$el
  } else if (context.value.querySelector) {
    tooltip = document.querySelector(context.value.querySelector)
  } else {
    throw new Error('`Content` or `querySelector` is missing')
  }

  tooltip.id = dataId
  const options = {
    enabled: context.value.enabled ?? true,
  }

  document.querySelector('body').append(tooltip)

  const popperInstance = createPopper(el, tooltip, {
    placement: context.value.placement || 'bottom',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 6],
        },
      },
      {
        name: 'arrow',
        options: {
          padding: 5,
        },
      },
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['bottom', 'right', 'top', 'left'],
        },
      },
    ],
  })

  const showEvents = context.value.showEvents ?? ['mouseenter', 'focus']
  const hideEvents = context.value.hideEvents ?? ['mouseleave', 'blur']

  showEvents.forEach?.((event) => {
    el.addEventListener(event, show.bind(null, tooltip, popperInstance, options))
  })

  hideEvents.forEach?.((event) => {
    el.addEventListener(event, hide.bind(null, tooltip, popperInstance, options))
  })

  instances[dataId] = {
    popperInstance,
    tooltipInstance,
    tooltip,
    options,
  }
}

const removeTooltip = (id) => {
  document.getElementById(id)?.remove()
}

function show (tooltip, popperInstance, options) {
  if (!options.enabled) {
    return
  }

  tooltip.setAttribute('data-show', '')

  popperInstance.setOptions(options => ({
    ...options,
    modifiers: [
      ...options.modifiers,
      { name: 'eventListeners', enabled: true },
    ],
  }))

  popperInstance.update()
}

function hide (tooltip, popperInstance, options) {
  if (!options.enabled) {
    return
  }

  tooltip.removeAttribute('data-show')

  popperInstance.setOptions(options => ({
    ...options,
    modifiers: [
      ...options.modifiers,
      { name: 'eventListeners', enabled: false },
    ],
  }))
}

/*
!!!najprostszy tooltip: v-tooltip="text" jako string - bottom

tooltip jako obiekt z opcjami v-tooltip {}:
content       - string    - Tresc tooltipa / tylko jak nie jest componentem
querySelector - string    - Komponent/div ktory jest tooltipem (BaseTooltipMedi)
                            querySelector(...) - nie jest brany pod uwage tekst z content
show          - bool      - programowalne pokazywanie (np ze zmiennej
                            wymaga wylaczenia showEvents: [] i hideEvents: [])
                            bez tego dzialaja tylko events
enable        - bool      - chwilowe wylaczenie pokazywania/ukrywania poppera
bemModifiers  - string[]  - wiadomo, css shit
placement     - string    - domyslna pozycja (@popper)
showEvents    - events[]  - pokazywanie eventy (@popper np. ['mouseenter'])
hideEvents    - events[]  - ukrywanie eventy (@popper np. ['mouseleave'])
*/
Vue.directive('tooltip', {
  inserted (el, context) {
    el.dataset.mediTooltip = `medi-tooltip-${++tooltipId}`
    createTooltip(el, context)
  },
  update (el, context) {
    const { tooltipInstance, tooltip, popperInstance, options } = instances[el.dataset.mediTooltip]

    if (tooltipInstance) {
      tooltipInstance.content = context.value.content
    }

    options.enabled = context.value.enabled ?? true

    if (typeof context.value.show === 'boolean') {
      context.value.show
        ? show(tooltip, popperInstance, options)
        : hide(tooltip, popperInstance, options)
    }
  },
  unbind (el) {
    const id = el.dataset.mediTooltip

    instances[id].popperInstance.destroy()
    delete instances[id]

    removeTooltip(el.dataset.mediTooltip)
  },
})
