
export default {
  name: 'FooterCopyrights',

  computed: {
    copyright () {
      return this.$store.state.storeConfig.storeConfig.copyright
    },
  },
}
