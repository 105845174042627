export default {
  methods: {
    goToUrl (url) {
      if (url?.startsWith?.(this.$config.appUrl) || url?.startsWith?.('/')) {
        this.$router.push(url.replace(this.$config.appUrl, ''))
      } else {
        window.open(url, '_blank')
      }
    },
  },
}
