
export default {
  name: 'MediButton',
  props: {
    type: {
      type: String,
      default: 'submit',
    },

    to: {
      type: [String, Object],
      default: undefined,
    },

    href: {
      type: String,
      default: undefined,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    hasLoader: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    component () {
      return this.to ? 'nuxt-link' : this.href ? 'a' : 'button'
    },
  },
}
