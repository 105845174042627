import types from './types'

export default {
  [types.IS_GIFTCARD_ENABLED] (state) {
    return state.storeConfig.amGiftCardSetting?.isEnabled || false
  },
  [types.GET_CATEGORIES] (state) {
    return state.category.children
  },
  [types.GET_CATEGORIES_IN_MENU] (state) {
    const categoryInMenuFilter = (acc, item) => {
      if (item.includeInMenu) {
        if (item.children.length > 0) {
          const children = item.children?.reduce(categoryInMenuFilter, [])

          acc.push({
            ...item,
            children,
          })
        } else {
          acc.push(item)
        }
      }
      return acc
    }

    return state.category.children?.reduce(categoryInMenuFilter, [])
  },

  [types.IS_MEGAMENU_AVAILABLE] (state) {
    return !!state.storeConfig.megaMenuId && !!state.megaCategory.length
  },

  [types.GET_MEGAMENU_NAVBAR_ITEMS] (state) {
    return state.megaCategory
      ?.filter(({ level }) => level === 0)
      .map((node) => {
        const hasChildren = state.megaCategory.some(({ parentId }) => parentId === node.nodeId)

        return {
          ...node,
          hasChildren,
        }
      })
  },
}
