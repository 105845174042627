const nip = {
  validate (nip) {
    if (typeof nip !== 'string') {
      return false
    }
    const weight = [6, 5, 7, 2, 3, 4, 5, 6, 7]

    nip = nip.replace(/[\\ \\-]/gi, '')

    const sum = nip.slice(0, -1).split('').map((z, i) => parseInt(z) * weight[i]).reduce((a, b) => a + b, 0)

    return sum % 11 === parseInt(nip.substring(9, 10))
  },
}

export { nip }
