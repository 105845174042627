export default [
  'AppointmentProduct',
  'AppointmentWithDoctorProduct',
  'MedicalCheckProduct',
  'MedicalPackageProduct',
  'OneTimeServiceProduct',
  'PackageOfServicesProduct',
  'PackagedServiceProduct',
  'PrepaidCardProduct',
  'SimpleService',
  'SimpleServiceProduct',
  'TrainingProduct',
]
