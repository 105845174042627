
export default {
  name: 'FooterHeading',

  props: {
    header: {
      type: String,
      required: true,
    },

    url: {
      type: String,
      default: '',
    },
  },
}
