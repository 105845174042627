
import { createNamespacedHelpers } from 'vuex'
import userTypes from '~/store/user/types'

import staticDiscountCode from '~/graphql/staticBlock/staticDiscountCode.graphql'

const regex = /(\[DISCOUNTCODE=)(\w+)\]/

const {
  mapGetters: mapGettersUser,
} = createNamespacedHelpers('user')

export default {
  name: 'BaseHtmlCode',
  props: {
    html: {
      type: String,
      default: '',
    },
  },

  data () {
    return {
      parsed: this.html.replace(regex, `<span data-discount-code="$2">${this.$t('wait')}...</span>`) ?? '',
    }
  },

  computed: {
    ...mapGettersUser({
      isMediclubMember: userTypes.GET_IS_MEDICLUB_MEMBER,
    }),
  },

  mounted () {
    const nodeEl = this.$refs.html.querySelector('[data-discount-code]')

    if (nodeEl) {
      this.isMediclubMember
        ? this.loadCode(nodeEl)
        : this.setLoginText(nodeEl)
    }
  },

  methods: {
    setLoginText (nodeEl) {
      nodeEl.innerHTML = `<a href="${this.localePath(`/auth/login?pathBefore=${this.$route.path}`)}">
        ${this.$t('To see the code login to Mediclub')}
        </a>`
    },

    setMessageText (nodeEl, message) {
      nodeEl.innerHTML = `<strong>${message}</strong>`
    },

    async loadCode (nodeEl) {
      const group = nodeEl.dataset.discountCode

      if (!group) {
        return this.setMessageText(nodeEl, this.$t('Invalid discount code'))
      }

      await this.$graphql({
        requestPayload: {
          mutation: staticDiscountCode,
          variables: { group },
        },
        successHandler: ({ data: { discountCode } }) => {
          this.setMessageText(nodeEl, discountCode)
        },
        errorHandler: ({ message }) => {
          this.setMessageText(nodeEl, message)
        },
      })
    },
  },
}
