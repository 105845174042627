import types from './types'

const mutations = {
  [types.SET_LOGGED] (state, isLogged) {
    state.isLogged = isLogged
  },
  [types.SET_PROFILE] (state, profile) {
    state.profile = profile
  },
  [types.SET_LOGIN_PENDING] (state, isLoginPending) {
    state.isLoginPending = isLoginPending
  },
  [types.SET_IS_MEDICLUB_MEMBER] (state, isMediclubMember) {
    state.isMediclubMember = isMediclubMember
  },
  [types.SET_QUESTIONNAIRE_STATUSES] (state, questionnaireStatuses) {
    state.questionnaireStatuses = questionnaireStatuses
  },
}

export default mutations
