const config = {
  messageId: 0,
  setter: [],
  messages: [],
}

const places = {
  default: 'default',
  header: 'header',
}

const defaultTime = 5000

/**
 * Subscribe message changes
 * @param {Function} onDataChange - On data change function
 */
const subscribe = (place, onDataChange) => {
  config.setter.push([place, onDataChange])

  notify()
}

/**
 * Unsubscribe message changes
 */
const unsubscribe = ([toastPlace, onDataChange]) => {
  config.setter = config.setter.filter((place, fn) => fn !== onDataChange && place !== toastPlace)
}

/**
 * Notify subscriber
 */
const notify = () => {
  if (config.setter) {
    config.setter.forEach(([toastPlace, fn]) => {
      const messages = config.messages.filter(({ place }) => place === toastPlace)

      fn(messages)
    })
  }
}

/**
 * Add messages
 * @param {Object} data - Message data
 */
const add = (data) => {
  const place = data.place in places ? data.place : places.default
  const id = ++config.messageId
  let timer = defaultTime

  if (data.timeout !== 0) {
    timer = setTimeout(remove.bind(null, id), data.timeout ?? defaultTime)
  }

  // do not show toast for apollo errors on build mode
  if (process.env.NODE_ENV !== 'development' && data.text.includes('query was in flight')) {
    return
  }

  if (needToAddToast({ ...data, place }, timer)) {
    config.messages.unshift({
      ...data,
      id,
      place,
      ...timer && { timer },
    })

    notify()
  }

  return id
}

/**
 * Checks if message exists and is first on the list
 * @param {Object} data - Message data
 */
const needToAddToast = (data) => {
  const toast = config.messages.find(({ place, text, type }) =>
    place === data.place &&
    text === data.text &&
    type === data.type)

  if (!toast) {
    return true
  }

  const position = config.messages.filter(({ place }) => place === data.place).indexOf(toast)

  if (position) {
    remove(toast.id)

    return true
  }

  clearTimeout(toast.timer)
  toast.timer = setTimeout(remove.bind(null, toast.id), data.timeout ?? defaultTime)

  return false
}

/**
 * Remove message
 * @param {Number} messageId - message.id
 */
const remove = (messageId) => {
  const { timer } = config.messages.find(({ id }) => id === messageId) || {}

  if (timer) {
    clearTimeout(timer)
  }

  config.messages = config.messages.filter(({ id }) => id !== messageId)
  notify()
}

/**
 * Flush messages
 */
const flush = () => {
  config.messages.slice(0, config.messages.length)
  notify()
}

export default function (ignore, inject) {
  inject('toast', {
    subscribe,
    unsubscribe,
    places,
    add,
    remove,
    flush,
  })
}
