export default {
  medicoverapteka: {
    pl: 'Zadbaj o zdrowie, urodę i samopoczucie od wewnątrz z suplementami diety Medicover Vital. Poznaj preparaty, które działają!',
    en: 'Take care of your health, beauty and well-being from the inside with Medicover Vital dietary supplements. Get to know dietary supplements that work!',
  },

  default: {
    pl: 'Medistore to wszystko dla Twojego zdrowia. Pakiety medyczne, konsultacje lekarskie i ponad 1800 badań i usług w jednym miejscu. Skorzystaj już teraz!',
    en: 'Medistore is all about your health. Medical packages, medical advice and over 1,800 tests and services all in one place. Learn more!',
  },

  damian: {
    pl: 'W Centrum Medycznym Damiana oferujemy pełen zakres konsultacji i badań diagnostycznych w Warszawie. Skorzystaj z oferty naszego sklepu i umów usługę w dogodnym terminie.',
    en: 'In Centrum Medyczne Damiana we offer a full range of consultations and diagnostic tests in Warsaw. Take advantage of our store\'s offerand get the service in atimely manner.',
  },
  medicover: {
    pl: 'Konsultacje lekarskie, teleporady, badania laboratoryjne, diagnostyka obrazowa. Ponad 40 placówek w całej Polsce. Wybierz dogodną lokalizację oraz termin i skorzystaj z pomocy doświadczonych specjalistów. Kupuj online taniej dzięki ofertom specjalnym i stałym rabatom.',
    en: 'Medical consultation, telemedicine, laboratory test, medical imaging. Over 40 facilities throughout Poland. Choose a convenient location and date and get help from our experienced specialists. Shop online with discounts and special offers.',
  },

  mindhealth: {
    pl: 'W Centrach Zdrowia Psychicznego MindHealth oferujemy opiekę psychologiczną i psychiatryczną w największych miastach Polski. Skorzystaj z oferty naszego sklepu i umów wizytę w dogodnym terminie.',
    en: 'At MindHealth Mental Health Centers, we offer psychological and psychiatric care in major Polish cities. Take advantage of our store\'s offerand schedulean appointmentat yourconvenience.',
  },
  rehasport: {
    pl: 'RehaSport to ortopedia, fizjoterapia i rehabilitacja w jednym miejscu. Sprawdź już dzisiaj i umów się na wizytę.',
    en: 'RehaSport is orthopedics, physiotherapy and rehabilitation in one place. Check this now and make an appointment.',
  },
  synevo: {
    pl: 'Badania laboratoryjne, badania krwi, badania na tarczycę - kup interesujący Cię pakiet badań w sklepie store.synevo.pl!',
    en: 'Laboratory tests, blood tests, thyroid tests - buy the test package you are interested in at store.synevo.pl!',
  },
}
