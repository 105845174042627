import { getNuxtServerInitActions, getNuxtClientInitActions } from '@strix/bootstrap/Store'

export const actions = {
  async nuxtServerInit (store, context) {
    await store.dispatch('storeConfig/getStoreConfig', context)
    await Promise.all([
      store.dispatch('storeConfig/getCategories', context),
      store.dispatch('staticBlock/getByIdentifiers', ['static-menu']),
      ...getNuxtServerInitActions().map(action => action(store, context)),
    ])
  },
  async nuxtClientInit (store, context) {
    await store.dispatch('user/getProfile', context)
    await Promise.all([
      ...getNuxtClientInitActions().map(action => action(store, context)),
    ])
  },
}
