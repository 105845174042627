import types from './types'
import { sortDataByPosition, sortItemsByLevelAndPosition } from '~/utils/Utils'

export default {
  async getStoreConfig ({ commit, dispatch }, { route, $config } = {}) {
    commit(types.SET_HAS_NEW_LOOK, route?.query.newLook ?? $config?.hasNewLook)

    await this.$graphql({
      requestPayload: {
        query: (await import(/* webpackChunkName: 'storeConfig' */ '~/graphql/config/storeConfig.graphql')).default,
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
        useGETForQueries: true,
        context: {
          fetchOptions: {
            method: 'GET',
          },
        },
      },
      successHandler: async ({ data: { allCmsBlocks, storeConfig, footer, recaptchaMutations, recaptchaQueries, getStoreReview } }) => {
        commit(types.SET_FOOTER, footer)
        commit(types.SET_STORECONFIG, storeConfig)
        commit(types.SET_RECAPTCHA_QUERIES, recaptchaQueries)
        commit(types.SET_RECAPTCHA_MUTATIONS, recaptchaMutations)
        commit(types.SET_STORE_REVIEW, getStoreReview)

        if (route?.query.snowdogMenuId && process.env.ENV !== 'prod') {
          commit(types.SET_MEGAMENU_SNOWDOG_ID, route.query.snowdogMenuId)
        }

        await dispatch('staticBlock/setAvailableIdentifiers', allCmsBlocks, { root: true })
      },
    })
  },

  async getCategories ({ state, commit }) {
    const identifier = typeof state.storeConfig.megaMenuId === 'string' ? state.storeConfig.megaMenuId : ''

    await this.$graphql({
      requestPayload: {
        query: (await import(/* webpackChunkName: 'storeConfig' */ '~/graphql/category/queries/getCategories.graphql')).default,
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
        variables: {
          identifier,
          getMegaMenu: !!identifier,
        },
        useGETForQueries: true,
        context: {
          fetchOptions: {
            method: 'GET',
          },
        },
      },
      successHandler: ({ data: { categoriesV2, snowdogMenuNodes } }) => {
        const categoryItems = categoriesV2.items
        const megaItems = snowdogMenuNodes?.items || []

        commit(types.SET_CATEGORY, categoryItems.map(sortDataByPosition)[0])
        commit(types.SET_MEGAMENU_CATEGORIES, megaItems.sort(sortItemsByLevelAndPosition))
      },
    })
  },
}
