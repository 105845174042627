
import { createNamespacedHelpers } from 'vuex'
import configTypes from '~/store/storeConfig/types'

const {
  mapGetters: mapGettersConfig,
} = createNamespacedHelpers('storeConfig')

export default {
  name: 'HeaderButton',
  props: {
    to: {
      type: String,
      required: true,
    },
  },

  computed: {
    ...mapGettersConfig({
      isMegaMenuAvailable: configTypes.IS_MEGAMENU_AVAILABLE,
    }),
  },
}
