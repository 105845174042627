import { extend, localize, setInteractionMode } from 'vee-validate'
// eslint-disable-next-line camelcase
import { required, email, min_value, confirmed } from 'vee-validate/dist/rules'
import pl from 'vee-validate/dist/locale/pl.json'
import { password } from '~/utils/formValidators/password'
import { name } from '~/utils/formValidators/name'
import { phone } from '~/utils/formValidators/phone'
import { postCode, postCodeRo } from '~/utils/formValidators/postCode'
import { pesel } from '~/utils/formValidators/pesel'
import { cnp } from '~/utils/formValidators/cnp'
import { gender } from '~/utils/formValidators/gender'
import { ageBetween } from '~/utils/formValidators/ageBetween'
import { outOfRange } from '~/utils/formValidators/outOfRange'
import { nip } from '~/utils/formValidators/nip'
import { noFutureDates } from '~/utils/formValidators/noFutureDates'
import { isOfAge } from '~/utils/formValidators/isOfAge'
import { url } from '~/utils/formValidators/url'
import { minLength } from '~/utils/formValidators/minLength'
import { blik } from '~/utils/formValidators/blik'

extend('email', email)
extend('required', required)
extend('min_checked', min_value)
extend('password', password)
extend('confirmed', confirmed)
extend('name', name)
extend('phone', phone)
extend('post_code', postCode)
extend('post_code_ro', postCodeRo)
extend('pesel', pesel)
extend('cnp', cnp)
extend('gender', gender)
extend('ageBetween', ageBetween)
extend('outOfRange', outOfRange)
extend('nip', nip)
extend('noFutureDates', noFutureDates)
extend('isOfAge', isOfAge)
extend('url', url)
extend('minLength', minLength)
extend('blik', blik)

localize('pl', pl)
setInteractionMode('eager')
