
import { createNamespacedHelpers } from 'vuex'
import userTypes from '~/store/user/types'

const {
  mapGetters: mapGettersUser,
} = createNamespacedHelpers('user')

export default {
  name: 'HeaderMinicartMediItem',

  props: {
    item: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapGettersUser({
      isMediclubMember: userTypes.GET_IS_MEDICLUB_MEMBER,
    }),

    isMaterialFee () {
      return this.item.product._typename === 'MaterialFee'
    },

    getPatientName () {
      const { personalData } = this.item.beneficiaries.items[0] || {}

      return `${personalData?.firstname || ''} ${personalData?.lastname || '-'}`
    },
  },
}
