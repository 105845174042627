/**
 * Returns boolean whether the parameter is a null or undefined
 *
 * @param {any} o
 * @returns {boolean}
 */
export const isNullish = o => o === null || o === undefined

/**
 * Returns boolean whether the parameter has constructor Object || Array
 *
 * @param {any} o
 * @returns {boolean}
 */
export const hasConstructor = o => o.constructor === Object || o.constructor === Array

/**
 * Returns boolean whether the object/array is empty
 *
 * @param {any} o
 * @returns {boolean}
 */
export const isEmpty = o => !isNullish(o) && Object.keys(o).length === 0 && hasConstructor(o)

/**
 *
 * @param {object} template
 * @param {object} data
 * @returns {object}
 */
export const reduceObjectByTemplate = (template, data) => {
  return Object.keys(template).reduce((acc, key) => {
    acc[key] = data[key] ?? template[key]
    return acc
  }, {})
}

export const isValueSet = (value) => {
  return !isNullish(value) && value !== ''
}

export const getI18nRouteParams = (availableLanguageVersions, availableLocales, paramName) => {
  const languageVersions = availableLanguageVersions?.reduce((acc, { lang, path }) => {
    acc[lang] = path
    return acc
  }, {}) || {}

  return availableLocales.reduce((acc, { code }) => {
    let path

    switch (code) {
      case 'pl': path = 'strona-niedostepna'
        break
      case 'en': path = 'page-not-available'
        break
      default:
        path = 'strona-niedostepna'
    }

    if (languageVersions[code]) {
      path = languageVersions[code]
    }

    acc[code] = {
      [paramName]: path,
    }
    return acc
  }, {})
}

export const sortDataByPosition = (el) => {
  if (el?.children.length > 0) {
    const sortChild = el.children.sort((a, b) => a.position - b.position)
    const children = sortChild.map(sortDataByPosition)

    return {
      ...el,
      children,
    }
  } else {
    return { ...el }
  }
}

export const sortItemsByLevelAndPosition = (itemA, itemB) => {
  return itemA.level - itemB.level || itemA.position - itemB.position
}

export const removeDiacritics = (el) => {
  return el
    .toLowerCase()
    .toString()
    .replace(/\u0142/g, 'l')
    .normalize('NFD')
    .replace(/[\u0300-\u036F]/g, '')
}

export const removeDiacriticsAndSpecialChars = (el) => {
  return el
    .toLowerCase()
    .toString()
    .replace(/\u0142/g, 'l')
    .normalize('NFD')
    .replace(/[\u0300-\u036F]/g, '')
    .replace(/[^a-zA-Z0-9]/g, '')
}

export const deleteTypeNameProperty = o => o._typename !== undefined && delete o._typename
