
export default {
  layout: ({ app, nuxtState }) => {
    const statusCode = app?.nuxt?.err?.statusCode || nuxtState?.error?.statusCode

    if (statusCode >= 500 && statusCode !== 501) {
      return 'clean'
    }

    return 'default'
  },

  props: {
    error: {
      type: Object,
      required: true,
    },
  },

  head () {
    return {
      htmlAttrs: {
        lang: this.$i18n.locale,
      },
    }
  },

  computed: {
    errorPage () {
      const { statusCode } = this.error

      if (statusCode === 503) {
        return 'Error503'
      }

      if (statusCode === 501) {
        return 'ErrorLanguageNotSupported'
      }

      if (statusCode >= 500) {
        return 'Error5xx'
      }

      return 'ErrorDefault'
    },
  },
}
