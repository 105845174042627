import underscoreToCamelCase from './underscoreToCamelCase'

function underscoreKeysToCamelCase (obj) {
  if (typeof obj !== 'object' || obj === null) {
    return obj
  }

  let newObj

  if (Array.isArray(obj)) {
    newObj = []
  } else {
    newObj = {}
  }

  for (const oldName in obj) {
    // Underscore to camel
    const newName = underscoreToCamelCase(oldName)

    // Only process if names are different
    if (Array.isArray(obj)) {
      newObj.push(obj[oldName])
    } else {
      newObj[newName] = obj[oldName]
    }

    // Recursion
    if (typeof newObj[newName] === 'object') {
      newObj[newName] = underscoreKeysToCamelCase(newObj[newName])
    }
  }

  return newObj
}

export default obj => underscoreKeysToCamelCase(obj)
