const phone = {
  validate (value) {
    return value.includes('(+48)')
      ? /^\(\+48\) \d{9}$/.test(value)
      : value.includes('(+40)')
        ? /^\(\+40\) \d{3}-\d{3}-\d{4}$/.test(value)
        : /^(\(\+\d{1,3}\))? ?(\d-?){6,19}\d$/.test(value)
  },
}

export { phone }
