export default {
  data () {
    return {
      isModalLoaded: false,
    }
  },
  provide () {
    return {
      modal: this.getModalComponent,
    }
  },
  methods: {
    async getModalComponent () {
      if (!this.isModalLoaded) {
        const { default: LazyBaseModal } = await import('~/components/base/BaseModal.vue')

        this.$options.components.LazyBaseModal = LazyBaseModal
        this.isModalLoaded = true

        await this.$nextTick()
      }
      return this.$refs.modal
    },
  },
}
