
export default {
  name: 'HeaderLogo',

  props: {
    desktopOnly: {
      type: Boolean,
      default: false,
    },

    hasNewLook: {
      type: Boolean,
      default: false,
    },

    isCheckout: {
      type: Boolean,
      default: false,
    },
  },
}
