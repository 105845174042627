function checkSchema (schema) {
  if (typeof schema !== 'object') {
    throw new TypeError('Schema is not object')
  }

  if (!schema.name?.length) {
    throw new Error('Schema name must be specified')
  }

  if (!Object.keys(schema.sections)?.length) {
    throw new Error('Schema must contain at least 1 section')
  }
}

function getValueForComponent (component) {
  const componentName = component?.toLowerCase()

  if (componentName.includes('validate')) {
    return 0
  }
  if (componentName.includes('array')) {
    return []
  }
  if (componentName.includes('checkbox')) {
    return false
  }
  if (componentName.includes('select')) {
    return null
  }
  if (componentName.includes('radio')) {
    return null
  }
  return ''
}

export const createModelFromSchema = (schema) => {
  checkSchema(schema)

  const model = {}

  Object.entries(schema.sections).forEach(([name, section]) => {
    Object.entries(section.elements).forEach(([name, element]) => {
      if (!name && !element.children) {
        throw new Error(`Element ${element.label} has no name`)
      }

      if (!element.component?.length) {
        throw new Error(`Element ${name} has no component`)
      }

      const component = element.component.toLowerCase()

      if (component.includes('select')) {
        if (!element.items?.length) {
          throw new Error(`Element ${name} has no items`)
        }
      }

      if (component.includes('radio')) {
        if (!element.fields?.length) {
          throw new Error(`Element ${name} has no fields`)
        }
      }

      if (name in model) {
        throw new Error(`Duplicated name "${name}"`)
      }

      if (name) {
        model[name] = getValueForComponent(element.component)
      }

      if (element.children) {
        Object.entries(element.children).forEach(([childName, childElement]) => {
          if (!childName) {
            throw new Error(`Child element ${childElement.label} has no name`)
          }

          if (childName in model) {
            throw new Error(`Duplicated name "${childName}"`)
          }

          model[childName] = getValueForComponent(element.component)
        })
      }
    })
  })

  return model
}

export const removeTypenameFieldsInGraphQlSchema = (obj) => {
  if (Array.isArray(obj)) {
    return obj.map(removeTypenameFieldsInGraphQlSchema)
  } else if (typeof obj === 'object' && obj !== null) {
    const newObj = {}

    for (const key in obj) {
      if (key !== '_typename') {
        newObj[key] = removeTypenameFieldsInGraphQlSchema(obj[key])
      }
    }
    return newObj
  } else {
    return obj
  }
}

export const createSchemaFromGraphQl = (graphQlSchema) => {
  const { sections, name } = graphQlSchema
  const modifiedArray = sections.map((page) => {
    const elements = page.elements.reduce((acc, element) => {
      acc[element.name] = element
      return acc
    }, {})

    return {
      ...page,
      elements,
    }
  })

  return {
    name,
    sections: modifiedArray,
  }
}

export const addFieldToSchema = (schema, fieldKey, fieldValue) => {
  for (const page of schema) {
    for (const elementKey in page.elements) {
      if (elementKey in page.elements) {
        page.elements[elementKey][fieldKey] = fieldValue
      }
    }
  }
}
