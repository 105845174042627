import { registerNuxtServerInitAction, registerNuxtClientInitAction } from '@strix/bootstrap/Store'

import { actions as beneficiary_actions } from '/home/gitlab-runner/builds/Dz_K4MD8/0/strix/frontend/apps/medicover-pl/medistore-headless-app/node_modules/@strix/checkout/store/beneficiary/actions.js'
import { getters as beneficiary_getters } from '/home/gitlab-runner/builds/Dz_K4MD8/0/strix/frontend/apps/medicover-pl/medistore-headless-app/node_modules/@strix/checkout/store/beneficiary/getters.js'
import { mutations as beneficiary_mutations } from '/home/gitlab-runner/builds/Dz_K4MD8/0/strix/frontend/apps/medicover-pl/medistore-headless-app/node_modules/@strix/checkout/store/beneficiary/mutations.js'
import { state as beneficiary_state } from '/home/gitlab-runner/builds/Dz_K4MD8/0/strix/frontend/apps/medicover-pl/medistore-headless-app/node_modules/@strix/checkout/store/beneficiary/state.js'
import { actions as checkout_actions } from '/home/gitlab-runner/builds/Dz_K4MD8/0/strix/frontend/apps/medicover-pl/medistore-headless-app/node_modules/@strix/checkout/store/checkout/actions.js'
import { getters as checkout_getters } from '/home/gitlab-runner/builds/Dz_K4MD8/0/strix/frontend/apps/medicover-pl/medistore-headless-app/node_modules/@strix/checkout/store/checkout/getters.js'
import { nuxtServerInit as checkout_nuxtServerInit, nuxtClientInit as checkout_nuxtClientInit } from '/home/gitlab-runner/builds/Dz_K4MD8/0/strix/frontend/apps/medicover-pl/medistore-headless-app/node_modules/@strix/checkout/store/checkout/index.js'
import { mutations as checkout_mutations } from '/home/gitlab-runner/builds/Dz_K4MD8/0/strix/frontend/apps/medicover-pl/medistore-headless-app/node_modules/@strix/checkout/store/checkout/mutations.js'
import { state as checkout_state } from '/home/gitlab-runner/builds/Dz_K4MD8/0/strix/frontend/apps/medicover-pl/medistore-headless-app/node_modules/@strix/checkout/store/checkout/state.js'
import { actions as customer_actions } from '/home/gitlab-runner/builds/Dz_K4MD8/0/strix/frontend/apps/medicover-pl/medistore-headless-app/node_modules/@strix/checkout/store/customer/actions.js'
import { getters as customer_getters } from '/home/gitlab-runner/builds/Dz_K4MD8/0/strix/frontend/apps/medicover-pl/medistore-headless-app/node_modules/@strix/checkout/store/customer/getters.js'
import { mutations as customer_mutations } from '/home/gitlab-runner/builds/Dz_K4MD8/0/strix/frontend/apps/medicover-pl/medistore-headless-app/node_modules/@strix/checkout/store/customer/mutations.js'
import { state as customer_state } from '/home/gitlab-runner/builds/Dz_K4MD8/0/strix/frontend/apps/medicover-pl/medistore-headless-app/node_modules/@strix/checkout/store/customer/state.js'
import { actions as shipping_actions } from '/home/gitlab-runner/builds/Dz_K4MD8/0/strix/frontend/apps/medicover-pl/medistore-headless-app/node_modules/@strix/checkout/store/shipping/actions.js'
import { getters as shipping_getters } from '/home/gitlab-runner/builds/Dz_K4MD8/0/strix/frontend/apps/medicover-pl/medistore-headless-app/node_modules/@strix/checkout/store/shipping/getters.js'
import { mutations as shipping_mutations } from '/home/gitlab-runner/builds/Dz_K4MD8/0/strix/frontend/apps/medicover-pl/medistore-headless-app/node_modules/@strix/checkout/store/shipping/mutations.js'
import { state as shipping_state } from '/home/gitlab-runner/builds/Dz_K4MD8/0/strix/frontend/apps/medicover-pl/medistore-headless-app/node_modules/@strix/checkout/store/shipping/state.js'
import { actions as search_actions } from '/home/gitlab-runner/builds/Dz_K4MD8/0/strix/frontend/apps/medicover-pl/medistore-headless-app/node_modules/@strix/search/store/search/actions.js'
import { mutations as search_mutations } from '/home/gitlab-runner/builds/Dz_K4MD8/0/strix/frontend/apps/medicover-pl/medistore-headless-app/node_modules/@strix/search/store/search/mutations.js'
import { state as search_state } from '/home/gitlab-runner/builds/Dz_K4MD8/0/strix/frontend/apps/medicover-pl/medistore-headless-app/node_modules/@strix/search/store/search/state.js'

export default function({ store: _store }) {
  _store.registerModule('beneficiary', { namespaced: true, actions: beneficiary_actions, getters: beneficiary_getters, mutations: beneficiary_mutations, state: beneficiary_state });
  _store.registerModule('checkout', { namespaced: true, actions: checkout_actions, getters: checkout_getters, mutations: checkout_mutations, state: checkout_state });
  _store.registerModule('customer', { namespaced: true, actions: customer_actions, getters: customer_getters, mutations: customer_mutations, state: customer_state });
  _store.registerModule('shipping', { namespaced: true, actions: shipping_actions, getters: shipping_getters, mutations: shipping_mutations, state: shipping_state });
  _store.registerModule('search', { namespaced: true, actions: search_actions, mutations: search_mutations, state: search_state });

  if (process.server) {
    registerNuxtServerInitAction('checkout', checkout_nuxtServerInit);
  }
  if (process.client) {
    registerNuxtClientInitAction('checkout', checkout_nuxtClientInit);
  }
}
