// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../static/backgrounds/medistore/bg.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".page[data-v-751dd8a1]{background:#fff;display:flex;flex-direction:column;min-height:100vh}.page__wrapper[data-v-751dd8a1]{flex:1;padding:var(--spacing-md-3) 0}@media(min-width:768px){.page__wrapper[data-v-751dd8a1]{padding:var(--spacing-md-3)}}.page__wrapper--with-background[data-v-751dd8a1]{padding-top:0}@media(min-width:1024px){.page__wrapper--with-background[data-v-751dd8a1]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:top;background-repeat:no-repeat}}.page__wrapper--category-page[data-v-751dd8a1]{background-color:var(--color-bg-1);padding-top:0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
