export default {
  data () {
    return {
      lastScrollY: 0,
      scrolled: false,
    }
  },

  mounted () {
    document.addEventListener('scroll', this.onScrollY)
  },

  beforeDestroy () {
    document.removeEventListener('scroll', this.onScrollY)
  },

  methods: {
    onScrollY () {
      const currScrollY = window.scrollY || document.documentElement.scrollTop

      this.scrolled = this.lastScrollY < currScrollY && currScrollY > 200
      this.lastScrollY = currScrollY
    },
  },
}
