
export default {
  name: 'BaseFlag',

  props: {
    flag: {
      type: String,
      required: true,
    },
  },
}
