
import items from '~/config/customerMenuItems'

export default {
  name: 'HeaderButtonMenuAccount',

  data () {
    return {
      items: items.filter(({ header }) => header),
    }
  },

  computed: {
    isLogged () {
      return this.$store.state.user.isLogged
    },
  },
}
