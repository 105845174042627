import Vue from 'vue'
import bpScss from '@/assets/scss/export.scss'

const state = Vue.observable({ breakpoints: {} })
const bp = Object.entries(bpScss).reduce((r, [bpName, bpValue]) => {
  r[bpName] = parseInt(bpValue)
  return r
}, {})

function getWidth () {
  if (typeof document === 'undefined') { return 0 }
  return Math.max(
    document.documentElement.clientWidth,
    window.innerWidth || 0,
  )
}

function getHeight () {
  if (typeof document === 'undefined') { return 0 }
  return Math.max(
    document.documentElement.clientHeight,
    window.innerHeight || 0,
  )
}

function update () {
  const width = getWidth()
  const height = getHeight()

  let name
  const bpCurrent = Object.entries(bp).reduceRight((r, [bpName, bpValue]) => {
    if (name) {
      r[bpName] = false
      return r
    }

    if (bpValue <= width) {
      name = bpName
      r[bpName] = true
    } else {
      r[bpName] = false
    }

    return r
  }, {})

  const bpUpDown = Object.entries(bpCurrent).reverse().reduce((r, [bpName], i, arr) => {
    r[`${bpName}AndDown`] = arr.slice(0, i + 1).some(([_, v]) => v)
    r[`${bpName}AndUp`] = arr.slice(i).some(([_, v]) => v)

    return r
  }, {})

  state.breakpoints = {
    width,
    height,
    name,
    thresholds: bp,
    ...bpCurrent,
    ...bpUpDown,
  }
}

const plugin = {
  install: () => {
    Vue.prototype.$css = state

    update()

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', update, { passive: true })
    }
  },
}

Vue.use(plugin)

export default plugin
