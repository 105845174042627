
const icons = {
  success: 'done-reverse',
  info: 'info-reverse',
  error: 'close-reverse',
  warning: 'warning',
}

export default {
  name: 'BaseToastItem',

  props: {
    message: {
      type: Object,
      required: true,
    },
  },

  computed: {
    iconType () {
      return icons[this.message.type]
    },
  },
}
