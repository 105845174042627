

export default {
  name: 'HeaderAccountLogout',
  props: {
    isMegaMenuAvailable: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    '$route.fullPath': {
      immediate: true,
      handler () {
        this.hideUserAdmission()
      },
    },
  },

  methods: {
    hideUserAdmission () {
      // eslint-disable-next-line prefer-regex-literals
      const regex = new RegExp(`/checkout/step${this.$cart.getCurrentStep()}`)

      return regex.test(this.$route?.matched[1]?.path)
    },
  },
}
