import types from './types'

const getters = {
  [types.GET_IS_MEDICLUB_MEMBER] (state) {
    return state.isLogged && state.isMediclubMember
  },

  [types.GET_EMAIL_COUNTER_COOKIE_NAME] (state) {
    return `user-login-${state.profile.email.split('').reduce((s, c) => Math.imul(31, s) + c.charCodeAt(0) | 0, 0)}`
  },

  [types.GET_SALESMANAGO_ID] (state) {
    return state.profile.salesManagoId || ''
  },
  [types.GET_IS_FOREIGNER] (state) {
    return state.profile.passport !== null || state.profile.passportMother !== null
  },
}

export default getters
