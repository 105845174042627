
import keepAlivePropsMixin from '~/mixins/keepAliveProps'

export default {
  mixins: [
    keepAlivePropsMixin,
  ],

  head () {
    return {
      htmlAttrs: {
        lang: this.$i18n.locale,
      },
    }
  },
}
