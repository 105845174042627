export default {
  computed: {
    isCountryPoland () {
      return this.$config.countryCode === 'PL'
    },

    isCountryRomania () {
      return this.$config.countryCode === 'RO'
    },
  },
}
