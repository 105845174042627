const postCode = {
  validate (value) {
    return /^\d{2}-\d{3}$/.test(value)
  },
}

const postCodeRo = {
  validate (value) {
    return /^\d{6}$/.test(value)
  },
}

export { postCode, postCodeRo }
