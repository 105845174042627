const password = {
  validate (value) {
    return (
      value.length >= 8 &&
      /[\d]/.test(value) &&
      /[A-ZŻŹĆĄŚĘŁÓŃ]/.test(value) &&
      /[a-zżźćńółęąś]/.test(value) &&
      /[!"`'#%&,:;<>=@{}~$()*+/\\?[\]^|\-_\\.]/.test(value)
    )
  },
}

export { password }
