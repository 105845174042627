
import cmsBlock from '~/mixins/cmsBlock'

export default {
  name: 'CmsHelloBar',
  mixins: [cmsBlock],

  data () {
    return {
      timer: null,
      days: null,
      hours: null,
      minutes: null,
      seconds: null,
      isEnded: false,
      visible: true,
    }
  },

  // przykładowy json
  // {"textDate": "Pozostało", "colorTextDate": "#ffffff", "endDateUTC": "2024-12-12T11:00:00Z", "header": "Higienizacja 8w1 za 329zł - zadbaj o zdrowie swoich zębów w wyjątkowej cenie !", "availablePages":["all"], "bgColor":"#00baf1", "fontColor": "#00FF00", "fontSmallColor":"#ffffff", "btnBgColor":"#d50764", "btnTextColor":"#ffffff", "bgClose":"#fd3db5", "text":"Zadbaj o swój uśmiech z MediClub!", "link": {"text":"Sprawdź", "url": "/p/morfologia-krwi"}}

  // określenie tylko niektórych stron
  // "availablePages":["/p/morfologia-krwi", "/c/badania/krwi", "/c/badania/usg", "/p/usg-jamy-brzusznej"]

  computed: {
    availablePages () {
      return !!this.blockContent?.availablePages.find(page => page === 'all') || this.blockContent?.availablePages.find(page => page === this.$route.path)
    },
  },

  mounted () {
    this.getTimer()
    this.timer = setInterval(this.getTimer, 1000)
  },

  beforeDestroy () {
    if (this.timer) {
      clearTimeout(this.timer)
    }
  },

  methods: {
    updateRemaining (distance) {
      this.days = Math.floor(distance / (1000 * 60 * 60 * 24))
      this.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
      this.seconds = Math.floor((distance % (1000 * 60)) / 1000)
    },

    getTimer () {
      const currentTime = new Date()
      const finished = new Date(`${this.blockContent?.endDateUTC}`)
      const distance = finished.getTime() - currentTime.getTime()

      this.updateRemaining(distance)

      if (distance <= 0) {
        clearInterval(this.timer)
        this.isEnded = true
      }
    },
  },

}
