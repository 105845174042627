export default function ({ $gtm, $config, route, app, beforeNuxtRender }) {
  if (process.server && route.query?.mode === 'saute') {
    beforeNuxtRender(() => {
      const headScipts = app.head.script
      const gtmScriptIndex = headScipts.findIndex(s => s.hid === 'gtm-script')

      headScipts.splice(gtmScriptIndex, 1)
    })
  } else {
    $gtm.init($config.gtmId)
  }
}
