
export default {
  name: 'HeaderMenuAccountDesktop',
  props: {
    profile: {
      type: Object,
      required: true,
    },
  },

  data () {
    return {
      list: {
        'customer.Mediclub zone': '/mediclub/',
        'customer.My Account': '/customer/orders/',
        Logout: '/auth/logout/',
      },
    }
  },
}
