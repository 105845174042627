
export default {
  name: 'HeaderInfoBar',

  data () {
    return {
      isLocaleOpen: false,
    }
  },

  computed: {
    infobar () {
      return this.$store.state.storeConfig?.storeConfig?.infoBarConfig
    },
  },

  methods: {
    localeToggle () {
      this.isLocaleOpen = !this.isLocaleOpen
    },

    onCloseLocale () {
      this.isLocaleOpen = false
    },
  },
}
